@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

:root {
	--primary: #efb6b2;
	--secondary: #4e4e4e;
	--error: #ff4a4a;
	--success: #5ABE76;
}

/* base styles & title */
body {
	font-family: 'Noto Serif';
	color: var(--secondary);
}
.App {
	max-width: 960px;
	margin: 0 auto;
	display: block;
}

/* auth styles */
.login-register-signout-button {
	font-family: 'Noto Serif';
	background: white;
	color: var(--primary);
	border: 1px solid var(--primary);
	padding: 0.5rem;
	border-radius: 0.5rem;
}
.login-signout-button:hover {
	background: var(--primary);
	color: white;
}

.auth-signed-in {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.auth-welcome-message {
	color: var(--secondary);
	margin-right: 1rem;
	font-size: 1rem;
}

.username {
	color: var(--primary);
}

.empty-or-unauthorized-image-view {
	color: var(--secondary);
	padding: 10rem;
	text-align: center;
}

/* auth styles */

#auth-close {
	align-self: flex-end;
	color: var(--primary);
	background-color: white;
	border: 1px solid white;
	padding: 0.5rem 0.25rem;
	font-weight: 700;
	font-family: 'Noto Serif';
}

.auth-inline-link-button {
	font-size: inherit;
	font-family: 'Noto Serif';
	align-self: flex-end;
	color: var(--primary);
	background: none;
	border: none;
	padding: 0;
	font-weight: 400;
	font-family: 'Noto Serif';
}

.login-and-register-forms {
	display: flex;
	flex-direction: column;
	align-content: center;
	width: 300px;
	background-color: white;
}

.login-and-register-forms input {
	padding-left: 1rem;
	line-height: 2rem;
	border: 1px solid var(--primary);
	border-radius: 0.5rem;
	margin: 0.5rem;
	font-family: 'Noto Serif';
}

.login-and-register-forms button {
	color: white;
	line-height: 2rem;
	background-color: var(--primary);
	border: 1px solid var(--primary);
	border-radius: 0.5rem;
	margin: 0.5rem;
	font-family: 'Noto Serif';
}

.login-and-register-forms-error {
	color: var(--error);
	display: flex;
	align-self: center;
	width: 284px;
	font-family: 'Noto Serif';
	font-size: 0.75rem;
	border: 1px solid var(--error);
	border-radius: 0.5rem;
	margin: 0.5rem;
	display: flex;
	flex-direction: column;
}

/* title styles */
.masthead {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.image-and-title {
	display: inline-flex;
	align-items: center;
}

.title h1 {
	color: var(--primary);
	font-size: 2.4rem;
	letter-spacing: 1px;
	font-weight: normal;
}
.title h2,
.title p {
	color: var(--secondary);
	text-align: center;
}
.title h2 {
	color: var(--secondary);
	margin-top: 60px;
	font-size: 2rem;
}
.title img {
	width: 64px;
	height: 64px;
	margin-right: 1rem;
}

.login-and-register-forms-error .error {
	color: var(--error);
}

/* upload form styles */

#uploadForm {
	margin: 30px auto 40px;
	text-align: center;
}
#uploadFile {
	height: 0;
	width: 0;
	opacity: 0;
}
#uploadLabel {
	display: block;
	width: 30px;
	height: 30px;
	border: 1px solid var(--primary);
	border-radius: 50%;
	margin: 10px auto;
	line-height: 30px;
	color: var(--primary);
	font-weight: bold;
	font-size: 24px;
	
}
#uploadLabel:hover {
	background: var(--primary);
	color: white;
}

.output {
	font-size: 0.75rem;
	display: flex;
	flex-direction: column;
}
.error {
	color: var(--error);
}

.error-message {
	color: var(--error);
	border: 1px solid var(--error);
	border-radius: 0.5rem;
	background-color: white;
	padding: 1rem;
	align-self: center;
	display: flex;
	flex-direction: column;
}

.success {
	color: var(--success);
}
.success-message {
	color: var(--success);
	border: 1px solid var(--success);
	border-radius: 0.5rem;
	background-color: white;
	padding: 1rem;
	align-self: center;
	display: flex;
	flex-direction: column;
}

.message-dismiss {
	align-self:flex-end;
	background-color: white;
	border: 1px solid white;
	padding: 0.25rem;
	font-weight: 700;
	font-family: 'Noto Serif';
}



/* progress bar styles */

.progress-bar {
	height: 5px;
	background: var(--primary);
	margin-top: 20px;
	border: 1px solid var(--primary);
	border-radius: 0.25rem;
}

/* image grid styles */

.img-grid {
	padding-top: 20px;
	margin: 20px auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
}
.img-wrap {
	overflow: hidden;
	height: 0;
	padding: 50% 0;
	/* padding controls height, will always be perfectly square regardless of width */
	position: relative;
	opacity: 0.8;
}
.img-wrap img {
	min-width: 100%;
	min-height: 100%;
	max-width: 150%;
	position: absolute;
	top: 0;
	left: 0;
}
/* modal styles */

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: rgba(0, 0, 0, 0.5);
}

.auth-modal {
	position: relative;
	top: 40%;
	display: flex;
	flex-direction: column;
	background-color: white;
	margin: 0 auto;
	width: 90vw;
	max-width: fit-content;
	height: fit-content;
	max-height: fit-content;
	padding: 1rem 3rem;
	border-radius: 0.5rem;
}

.backdrop img {
	display: block;
	max-width: 60%;
	max-height: 80%;
	margin: 60px auto;
	box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
	border: 3px solid white;
}
